type OrderStatusColor = 'error' | 'warning' | 'info' | 'success' | 'deepPurple';

export interface OrderStatus {
    id: string;
    label: string;
    color: OrderStatusColor;
}

export const orderStatus: Record<string, OrderStatus> = Object.freeze({
    NOT_READY: {id: 'NOT_READY', label: 'Niegotowe', color: 'error'},
    NEW: {id: 'NEW', label: 'Nowe', color: 'warning'},
    PROCESSING: {id: 'PROCESSING', label: 'W realizacji', color: 'warning'},
    SUSPENDED: {id: 'SUSPENDED', label: 'Wstrzymane', color: 'error'},
    READY_FOR_SHIPMENT: {id: 'READY_FOR_SHIPMENT', label: 'Do wysłania', color: 'warning'},
    READY_TO_PACK: {id: 'READY_TO_PACK', label: 'Do pakowania', color: 'warning'},
    READY_FOR_PICKUP: {id: 'READY_FOR_PICKUP', label: 'Do odbioru', color: 'info'},
    SENT: {id: 'SENT', label: 'Wysłane', color: 'info'},
    PICKED_UP: {id: 'PICKED_UP', label: 'Odebrane', color: 'success'},
    CANCELLED: {id: 'CANCELLED', label: 'Anulowane', color: 'error'},
} as const);

export const groupOrderStatus: Record<string, OrderStatus> = Object.freeze({
    ALL: {
        id: 'NOT_READY,NEW,PROCESSING,SUSPENDED,READY_TO_PACK,READY_FOR_SHIPMENT,READY_FOR_PICKUP,SENT,PICKED_UP,CANCELLED',
        label: 'Wszystkie',
        color: 'deepPurple',
    },
    ACTIVE: {id: 'NEW,READY_FOR_SHIPMENT,PROCESSING,READY_TO_PACK', label: 'Aktywne', color: 'deepPurple'},
    DELIVERY: {id: 'SENT,READY_FOR_PICKUP', label: 'W drodze', color: 'deepPurple'},
} as const);

export const orderStatuses = Object.freeze(Object.values(orderStatus).concat(Object.values(groupOrderStatus)));

export const activeOrderTabsByStatus: Record<string, OrderStatus> = Object.freeze({
    NEW: {id: 'NEW', label: 'Nowe', color: 'warning'},
    PROCESSING: {id: 'PROCESSING', label: 'W realizacji', color: 'warning'},
    READY_TO_PACK: {id: 'READY_TO_PACK', label: 'Do pakowania', color: 'warning'},
    READY_FOR_SHIPMENT: {id: 'READY_FOR_SHIPMENT', label: 'Do wysłania', color: 'warning'},
    NOT_READY: {id: 'NOT_READY', label: 'Niegotowe', color: 'error'},
} as const);

export const inpostStatus: Record<string, string> = Object.freeze({
    confirmed: 'Przygotowana przez nadawcę',
    dispatched_by_sender: 'Paczka nadana w automacie Paczkomat',
    taken_by_courier: 'Zabrana przez kuriera',
    adopted_at_source_branch: 'Przyjęta w oddziale InPost',
    sent_from_source_branch: 'W drodze',
    out_for_delivery: 'Przekazana do doręczenia',
    ready_to_pickup: 'W paczkomacie docelowym',
    delivered: 'Odebrana',
    returned_to_sender: 'Zwrot do nadawcy',
} as const);
